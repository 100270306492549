import { get, isEmpty, map } from "lodash"
import { FlexDivCentered, OptionCard } from "../atoms"

export const BubbleOptions = (props) => {
    const list = get(props, "list", []), name = get(props, "name", ""),
        selection = get(props, "selection", "");
    if (isEmpty(list)) {
        return null;
    }
    return (<FlexDivCentered>{map(list, (option, key) => (
        <OptionCard key={`${name}-${key}`} title={get(option, "title", "")} subtitle={get(option, "subtitle", "")} selected={get(option, "service_id", "") === selection}
            value={option.service_id} onClick={props.onClick} />
    ))}
    </FlexDivCentered>)
}