import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { B2bOverview, CartSummary, Explanation, Treatments, AboutAndLinks } from '../../organisms';
import { get, size, split } from 'lodash';
import { setB2bInfo, setLoading } from '../../../actions'
import { CtaButton, HalfHeightHeroImage, LinkCtaButton, SmallHalfHeightHeroImage } from '../../atoms';
import { getBusinessProducts, pageViewEvent } from '../../../helpers';
import { ArrowUpward } from '@mui/icons-material';
import { countVisit } from '../../../helpers/eventApis';
import NotFound from '../../organisms/NotFound';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            width: window.innerWidth,
            openDrawer: false,
            notFound: false
        }
        this.imageSection = this.imageSection.bind(this);
        this.contentInGrids = this.contentInGrids.bind(this);
        this.cartSummaryGrid = this.cartSummaryGrid.bind(this);
        this.cartButton = this.cartButton.bind(this);
    }
    componentDidMount() {
        this.props.setLoading(true)
        window.scrollTo(0, 0);
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
        let { pathname, search, hostname } = window.location;
        let uuid = get(split(pathname, "/", 2), "1", ""),
            isDraftView = false,
            business_name_arr = split(hostname, ".business.soothe.com", 1);
        const parsedParams = queryString.parse(search);
        if (Object.keys(parsedParams).includes('draft')) {
            isDraftView = Boolean(parsedParams['draft'] === "true")
        }

        if (!uuid) {
            this.setState({ notFound: true }, () => this.props.setLoading(false))
        }
        countVisit(uuid, get(business_name_arr, "0", ""));
        getBusinessProducts(uuid, get(business_name_arr, "0", ""), isDraftView, (response) => {
            let data = get(response, "data", {}),
                location_name = get(data, "name", ""),
                business_name = get(data, "business.name", ""),
                location_id = get(data, "id", ""),
                business_id = get(data, "business.id", ""),
                sub_domain = get(data, "business.sub_domain", "");
            this.props.setB2bInfo({ ...data, location_id, business_id, location_name, business_name, uuid, isDraftView, sub_domain })
            this.props.setLoading(false)
            pageViewEvent(business_name, location_name);
        }, (err) => {
            this.props.setLoading(false)
            let is422 = get(err, "response.status", "") === 422;
            if (is422) {
                this.setState({ notFound: true })
            }
        });
    }
    imageSection() {
        let bannerImage = get(this.props, "b2bInfo.bannerimage", "");
        return (bannerImage ? this.state.width <= 992 ?
            <SmallHalfHeightHeroImage bgImg={bannerImage} className="darken-bg" />
            : <HalfHeightHeroImage bgImg={bannerImage} className="darken-bg" /> : <div className='pt-88'></div>)
    }
    cartSummaryGrid() {
        return (<div className={`col-sm-4`}>
            <CartSummary />
        </div>)
    }
    cartButton() {
        let cpSize = size(get(this.props, "cart.cartProducts", []));
        return <>
            <LinkCtaButton copy={<><ArrowUpward style={{ marginRight: "12px" }} />See Treatments Menu</>} href="#treatments" />
            {cpSize && !this.state.openDrawer ?
                <CtaButton className="special-view-cart-mobile" copy={<div className='display-flex align-items-center'>
                    <div className='flex-grow-1'>View Cart</div>
                    <div className="background-deep-orange content-primary-white p-5-13 border-radius-50 size-12-20" >{cpSize}</div>
                </div>}
                    onClick={() => {
                        this.setState({ openDrawer: true })
                    }} />
                : null}
        </>
    }
    contentInGrids() {
        let width = this.state.width;
        if (width >= 992) {
            return (<div className='row'>
                <div className='col-md-8'>
                    <B2bOverview />
                    <Treatments />
                    <Explanation />
                    <AboutAndLinks />
                </div>
                {this.cartSummaryGrid()}
            </div>)
        }
        else if (width >= 768) {
            return (<div className='row'>
                <div className='col-12 '>
                    <B2bOverview />
                </div>
                <div className='col-12 col-sm-8'>
                    <Treatments />
                </div>
                {this.cartSummaryGrid()}
                <div className='col-12'>
                    <Explanation />
                    <AboutAndLinks />
                </div>
            </div>)
        } else {
            return (<>
                <B2bOverview />
                <Treatments openCartDrawer={() => this.setState({ openDrawer: true })} />
                <Explanation />
                <AboutAndLinks />
                <CartSummary isDrawerOpen={this.state.openDrawer} hideDrawer={() => this.setState({ openDrawer: false })} />
                {this.cartButton()}
            </>)
        }
    }
    render() {
        if (this.state.notFound) {
            return <NotFound />
        }
        return (<div>
            {this.imageSection()}
            <div className='background-primary ptb-24'>
                <div className='max-width-80'>
                    {this.contentInGrids()}
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo,
    cart: state.cart
});

export default connect(mapStateToProps, { setB2bInfo, setLoading })(Home);