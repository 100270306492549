import React from 'react';
import { connect } from 'react-redux';
import { setB2bInfo, setCart } from '../../actions';
import { isEmpty, get, map, remove, values, size, compact } from 'lodash';
import { CardWithBorderP16, CtaButton, Header4, Header6, LightSmall1, LinkButton, RegularHeader5, RoundedIcon, SecondaryCtaButton, Small3 } from '../atoms';
import { Delete } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { bookNowEvent, initCartJson } from '../../helpers';
import ModalWithImageHeader from './PopupModal';
class CartSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            isOpenErrorModal: false,
            errorMessage: ""
        }
        this.relevantCartContent = this.relevantCartContent.bind(this);
        this.relevantCartAction = this.relevantCartAction.bind(this);
        this.removeProductFromCart = this.removeProductFromCart.bind(this);
        this.bookNow = this.bookNow.bind(this);
        this.mobileSizeCart = this.mobileSizeCart.bind(this);
        this.renderView = this.renderView.bind(this);
        this.errorModal = this.errorModal.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }
    bookNow() {
        if (get(this.props, "b2bInfo.isDraftView", false)) {
            if (this.props.hideDrawer) {
                this.props.hideDrawer()
            }
            this.setState({ isOpenErrorModal: true, errorMessage: "You are in preview mode" })
            return;
        }
        const { business_name, location_name, website_link, currency, bannerimage, address, uuid, business_id, location_id, working_hours, sub_domain } = get(this.props, "b2bInfo", {}),
            { cartProducts, product_id } = get(this.props, "cart", {});
        let cart_products = [];
        if (!isEmpty(cartProducts)) {
            cart_products = map(cartProducts, (value) => ({
                option_ids: compact(values(get(value, "options", {}))),
                client_name: get(value, "clientName", "")
            }))
        }
        let listingPageStorage = {
            "user_kind": "guest",
            "currency": currency,
            "website_link": website_link,
            "bannerimage": bannerimage,
            business_name,
            location_name,
            business_id,
            location_id,
            uuid,
            sub_domain,
            "cart": {
                cart_products,
                from_b2b: true,
                b2b_type: "b2b",
                business_name,
                location_name,
                business_id,
                location_id,
                product_id,
                double: size(cart_products) === 2
            },
            "address": address,
            working_hours

        }
        bookNowEvent(size(cart_products))

        initCartJson(JSON.stringify(listingPageStorage), (response) => {
            let uuid = get(response, "data.uuid", "");
            if (uuid) {
                window.location.replace(`${process.env.REACT_APP_CONSUMER_URL}/booking/TIMING?guest=${uuid}&business=${business_id}`)
            }
        })
    }
    removeProductFromCart(indexToRemove) {
        let cartProducts = get(this.props, "cart.cartProducts", []),
            product_id = get(this.props, "cart.product_id", ""),
            slug = get(this.props, "cart.slug", "");
        remove(cartProducts, (v, k) => (k === indexToRemove));
        if (isEmpty(cartProducts)) {
            product_id = "";
            slug = "";
        }
        this.props.setCart({ ...this.props.cart, cartProducts, product_id, slug })
    }
    mobileSizeCart() {
        let full_2 = size(get(this.props, "cart.cartProducts", [])) >= 2;
        return (<Drawer open={this.props.isDrawerOpen} anchor={'bottom'} >
            <div className='p-24 border-radius-16-0'>
                <Header4>Your cart</Header4>
                <RegularHeader5 className="mb-24">Choose up to 2</RegularHeader5>
                {this.relevantCartContent()}
                <div className=''>
                    {full_2 ? null : <SecondaryCtaButton text="+ Add service" onClick={this.props.hideDrawer}
                        className="width-100 sm-p-16-20 bold mb-16 sm-size-18-24" />}
                    {this.relevantCartAction()}
                </div>
            </div>
        </Drawer>)
    }
    relevantCartContent() {
        const cartProducts = get(this.props, "cart.cartProducts", []);
        if (!isEmpty(cartProducts)) {
            return (<>
                {map(cartProducts, (cp, it) => (
                    <div key={`cp-${it}`}>
                        <div className={`row ${it === 0 ? "" : "mt-22"}`}>
                            <div className='col-10 col-sm-10'>
                                <Header6 className="bold overflow-wrap-break-word">{get(cp, "title", "")}</Header6>
                                {get(cp, "subtitle", "") ? <LightSmall1 className="m-b-4">{get(cp, "subtitle", "")}</LightSmall1> : null}
                                <LightSmall1>{get(cp, "price", "")}</LightSmall1>
                            </div>
                            <div className='col-2 col-sm-2'>
                                <RoundedIcon icon={<Delete style={{ fontSize: "12px" }} />}
                                    onClick={() => this.removeProductFromCart(it)} />
                            </div>
                        </div>
                    </div>
                ))}
            </>)
        }
        return (<>
            <Header6 className="bold size-16-20">Your cart is empty</Header6>
            <LightSmall1>Select a treatment to get started</LightSmall1>
        </>)
    }
    relevantCartAction() {
        const filledCartProducts = !isEmpty(get(this.props, "cart.cartProducts", []));
        if (filledCartProducts) {
            return (<>
                <div className='mb-16'>
                    <CtaButton copy="Book now" onClick={this.bookNow} />
                </div>
                <Small3 className='text-align-center content-tertiary'>
                    You won't be charged yet
                </Small3>
            </>)
        }
        if (this.state.width >= 768) {
            return (<LinkButton href="#treatments" text="See Treatments" className="bold" />);
        }
    }
    errorModal() {
        return <ModalWithImageHeader
            isOpen={this.state.isOpenErrorModal} close={() => this.setState({ isOpenErrorModal: false, errorMessage: "" })}
            applyBtnCopy="Close" apply={() => this.setState({ isOpenErrorModal: false, errorMessage: "" })}
            footerAddon={{ content: " " }}
        >
            <Header4 className="text-align-center">{this.state.errorMessage}</Header4>
        </ModalWithImageHeader>
    }
    renderView() {
        if (this.state.width >= 768) {
            return (<CardWithBorderP16>
                <Header4>Your cart</Header4>
                <RegularHeader5 className="mb-24">Choose up to 2 from the same treatments category</RegularHeader5>
                {this.relevantCartContent()}
                <div className='mt-40'>
                    {this.relevantCartAction()}
                </div>
            </CardWithBorderP16>)
        }
        return this.mobileSizeCart()
    }
    render() {
        return <>
            {this.renderView()}
            {this.errorModal()}
        </>
    }
}
const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo,
    cart: state.cart
});

export default connect(mapStateToProps, { setB2bInfo, setCart })(CartSummary);