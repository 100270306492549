import axios from 'axios';
import { ACCEPT_HEADER, API_ROOT, NODE_API_ROOT } from '../apiConfig';

export function getBusinessProducts(uuid, sub_domain, isDraftView, callBackFunc, errorHandler) {
    if (uuid) {
        axios.get(`${API_ROOT}/partner/consumer_read?uuid=${uuid}&draft=${isDraftView}&sub_domain=${sub_domain}`, { withCredentials: false }, ACCEPT_HEADER)
            .then((response) => {
                const { result, success } = response.data;
                if ((result || success) && callBackFunc) {
                    callBackFunc(response.data);
                }
            }).catch((err) => {
                if (errorHandler) {
                    errorHandler(err);
                } else {
                    console.log(err);
                }
            });
    }
}
export function getBlogPosts(callBackFunc, errorHandler) {
    axios.get(`${API_ROOT}/blogs/new_posts`, { withCredentials: false }, ACCEPT_HEADER)
        .then((response) => {
            const { result } = response.data;
            if (result && callBackFunc) {
                callBackFunc(response);
            }
        }).catch((err) => {
            if (errorHandler) {
                errorHandler(err);
            } else {
                console.log(err);
            }
        });
}
export function getMaintenanceStatus(callBackFunc, errorHandler) {
    axios.get(`${API_ROOT}/partner/maintenance`, { withCredentials: false }, ACCEPT_HEADER)
        .then((response) => {
            const { result } = response.data;
            if (result && callBackFunc) {
                callBackFunc(response);
            }
        }).catch((err) => {
            if (errorHandler) {
                errorHandler(err);
            } else {
                console.log(err);
            }
        });
}
