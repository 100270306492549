import React from 'react';
import { connect } from 'react-redux';
import { HOW_IT_WORKS } from '../../static/constants';
import { map } from 'lodash';
import { Header3, Small1 } from '../atoms';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class Explanation extends React.Component {
    constructor(props) {
        super(props);
        this.slickDisplay = this.slickDisplay.bind(this);
    }
    slickDisplay() {
        return (<Slider
            slidesToShow={4}
            swipeToSlide={true}
            focusOnSelect={true}
            infinite={false}
            responsive={[
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2.25,
                        arrows: false
                    }
                }
            ]}>
            {map(HOW_IT_WORKS, (elmnt, ind) => (
                <div key={`how-${ind}`} className="pr-16">
                    <div className='mb-24'><img className='m-auto' src={elmnt.imgSrc} alt={elmnt.title} /></div>
                    <Small1>{elmnt.title}</Small1>
                </div>
            ))}
        </Slider>);
    }

    render() {
        return (<div className='ptb-16'>
            <Header3>How it works</Header3>
            <div className='background-secondary border-radius-4 p-24 mb-48 text-align-center'>
                {this.slickDisplay()}
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(Explanation);