import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import handsIcon from '../../static/images/icon-static-loading.png';
import { connect } from 'react-redux';

function CircleSpinner() {

    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../static/images/spinner-with-blur-white.json')
        })
    }, [])

    return (
        <div className="circle-spinner" ref={container}></div>
    );
}
class LoadingOverlay extends React.Component {
    constructor(props) {
        super(props);

    }
    componentDidUpdate() {

    }
    render() {
        let { isLoading } = this.props.loading;
        return (isLoading ? <div className='grayed-overlay'>
            <CircleSpinner />
            <img src={handsIcon} alt="loading..." className='hands-icon' />
        </div>
            : null)
    }
}
const mapStateToProps = state => ({
    loading: state.loading
});

export default connect(mapStateToProps, {})(LoadingOverlay);