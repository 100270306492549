import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/home";
import Header from "./components/organisms/header";
import Footer from './components/organisms/footer';
import { Loader } from './components/organisms';
import { useEffect, useState } from 'react';
import { getMaintenanceStatus } from './helpers';
import { get } from 'lodash';
import Maintenance from './components/pages/home/maintenance';
function App() {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getMaintenanceStatus((resp) => {
        setIsOffline(get(resp, "data.data.is_offline", false))
      }, (_err) => {
      });
    };

    fetchData();
  }, []);
  return (
    <div className="App">
      {isOffline ? <Maintenance />
        : <>
          <Header />
          <Loader />
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Home />} exact />
            </Routes>
          </BrowserRouter>
          <Footer />
        </>}
    </div>
  );
}

export default App;
