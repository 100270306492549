import Amplitude from 'amplitude-js';

if (process.env.REACT_APP_PRODUCTION === 'true') {
    // Initialize Amplitude
    Amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_SDK_KEY);
}
export function pageViewEvent(business_name, location_name) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') return;

    const eventName = 'sbe_listing_page_view';
    const eventHash = { business_name, location_name };

    // Amplitude Events
    Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function treatmentSelectionEvent(treatment_category, treatment_name) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') return;

    const eventName = 'sbe_listing_page_treatment_selection';
    const eventHash = { treatment_name, treatment_category };

    // Amplitude Events
    Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function addToCartEvent(treatment_name, {
    duration,
    modality,
    gender,
    client_name }) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') return;

    const eventName = 'sbe_listing_page_add_to_cart';
    const eventHash = {
        treatment_name, treatment_options: {
            duration: duration || "n/a",
            modality,
            gender: gender || "n/a",
            client_name
        }
    };

    // Amplitude Events
    Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function bookNowEvent(number_of_treatments) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') return;

    const eventName = 'sbe_listing_page_book_now_button';
    const eventHash = { number_of_treatments };

    // Amplitude Events
    Amplitude.getInstance().logEvent(eventName, eventHash);
}