import React from 'react';
import { Header6, LightSmall1 } from '../atoms';

class CTAStickyFooter extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { text, addon, disabled, action, additionalClass, submitted, additionalWrapperClass } = this.props;
        return (<div className='position-relative'>
            <div className={`ctaButton-wrapper ${additionalWrapperClass}`}>
                {addon ? <span className='mr-32 txt-align-left'>
                    <Header6 className="bold">{addon.content || ""}</Header6>
                    <LightSmall1>{addon.subContent || ""}</LightSmall1>
                </span> : null}
                <button className={`btn ctaButton-enabled ${additionalClass || ""}`}
                    disabled={disabled}
                    onClick={() => {
                        if (!disabled && !submitted) {
                            action();
                        }
                    }}>
                    {text || ""}
                </button>
            </div>
        </div>);
    }
}

export default CTAStickyFooter;