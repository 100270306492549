import axios from 'axios';
import { isEmpty } from 'lodash';
import { API_ROOT, ACCEPT_HEADER } from '../apiConfig';
export function initCartJson(cart_json, callBackFunc, errorHandler) {
    if (cart_json) {
        axios.post(`${API_ROOT}/guest/init_guest/init_guest_cart`,
            {
                cart_json
            },

            { withCredentials: true }, ACCEPT_HEADER).then((response) => {
                let { success } = response.data;
                if (success && callBackFunc) {
                    callBackFunc(response);
                }
            }).catch((err) => {
                if (errorHandler) {
                    errorHandler(err);
                } else {
                    console.log(err);
                }
            });
    }
}