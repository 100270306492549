import { Header5, Small3 } from "./Typography";

export const CardWithBorderP16 = (props) => (<div className="light-border border-radius-16 p-16 background-primary gray-shadow sm-mt-88 sticky-on-scroll">{props.children}</div>);
export const CardWithBorderP24 = (props) => (<div className="light-border border-radius-16 p-24 background-primary">{props.children}</div>);
export const OptionCard = ({ title, subtitle, icon, selected, value, onClick }) => (<div className={`centered-cell ${selected ? "selected" : ""}`}
    onClick={() => onClick(value)}>
    <div className='p-10'>
        {icon ? <div className='cell-icon'>{icon}</div> : null}
        <Header5>{title || ""}</Header5>
        {subtitle ? <Small3 className="m-t-4">{subtitle}</Small3> : null}
    </div>
</div>);