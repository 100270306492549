import { Small1, Small3, ImageLink } from "../atoms";
export const HorizontalImgWithText = ({ src, alt, label, text, href, additionalClass }) => (<div className={`row mb-32 ${additionalClass}`}>
    <div className="col-3">
        <ImageLink src={src} alt={alt} href={href} className="width-100" />
    </div>
    <div className="col-9">
        <a className="text-decoration-none" href={href}>
            <div className='content-secondary'><Small3>{label}</Small3></div>
            <div className='content-primary-black'><Small1>{text}</Small1></div>
        </a>
    </div>
</div>
)
export const HorizontalIconWithText = ({ icon, text }) => (
    <div className='row mb-24'>
        <div className='col-1 col-md-2 smaller-width'>{icon}</div>
        <div className='col-11 col-md-10 pr-0 pl-5'>{text}</div>
    </div>
)