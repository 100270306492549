import React from 'react';
import no_data_svg from '../../static/images/No_data.svg';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }

    render() {
        return <div className='text-align-center width-35 sm-width-100 height-100vh pt-65 mb-48'>
            <div><img src={no_data_svg} alt="No products found" className='width-100 mb-24' /></div>
            <div className='color-light-ash size-44-52 m-24 bold'>NOT FOUND</div>
            <div className='color-darkest-blue size-18-24 m-24'>Sorry. No Products are Available.</div>
            <div className='m-24'>
                <button className='btn background-deep-blue size-14-16 content-primary-white p-16-20 border-radius-8 bold' onClick={() => {
                    window.location.replace(`https://www.soothe.com/booking`)
                }}>Go to Soothe booking</button>
            </div>
        </div>
    }
}

export default NotFound;