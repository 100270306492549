import { FlexDivSpaceBetween, Header5, Small2, WhiteLink } from "../atoms";
import { map } from 'lodash';
import { FOOTER_LINKS } from "../../static/constants";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Add as ExpandMore, Remove as ExpandLess } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { useState } from "react";
export const HorizontalFooterLinks = () => (<FlexDivSpaceBetween>
    {map(FOOTER_LINKS, (colObj, ind) => (<div key={`footer-col-${ind}`}>
        <Header5 className="mb-16">{colObj.title}</Header5>
        {map(colObj.linksList, (lnk, it) => (<div key={`footer-link-${ind}-${it}`} >
            <WhiteLink href={lnk.linkAddress}>
                <Small2>{lnk.linkText}</Small2></WhiteLink>
        </div>))}
    </div>))}
</FlexDivSpaceBetween>);
export const VerticalFooterLinks = () => {
    const [openedIndex, setOpenedIndex] = useState(-1);
    return (<List component='nav' aria-labelledby='nested-list-subheader'>
        {FOOTER_LINKS.map((colObj, ind) => {
            return (
                <div key={`footer-collapsible-${ind}`}>
                    <ListItem key={`list-item-${ind}`}
                        button onClick={() => {
                            if (openedIndex !== ind) {
                                setOpenedIndex(ind);
                            } else {
                                setOpenedIndex(-1);
                            }
                        }}>
                        <ListItemText primary={colObj.title} />
                        {openedIndex === ind ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse key={`collapse-${ind}`} in={openedIndex === ind}
                        timeout='auto' unmountOnExit className="p-16">
                        {map(colObj.linksList, (lnk, it) => (<div key={`footer-link-${ind}-${it}`}>
                            <WhiteLink href={lnk.linkAddress}>
                                <Small2>{lnk.linkText}</Small2></WhiteLink>
                        </div>))}
                    </Collapse>
                    <Divider />
                </div>
            );
        })}
    </List>)
}