export const Header1 = (props) => (<div className={`size-44-52 mb-16 bold ${props.className || ""}`}>{props.children}</div>);
export const Header2 = (props) => (<div className={`size-36-44 bold ${props.className || ""}`}>{props.children}</div>);
export const Header3 = (props) => (<div className="size-28-36 mb-16 bold">{props.children}</div>);
export const Header4 = (props) => (<div className={`size-24-32 bold ${props.className || ""}`}>{props.children}</div>);
export const Header5 = (props) => (<div className={`size-18-24 bold ${props.className || ""}`}>{props.children}</div>);
export const Header6 = (props) => (<div className={`size-16-24 m-b-4 ${props.className || ""}`}>{props.children}</div>);

export const RegularHeader5 = (props) => (<div className={`size-18-28 ${props.className || ""}`}>{props.children}</div>);
export const LightHeader5 = (props) => (<div className={`size-18-24 content-secondary ${props.className || ""}`}>{props.children}</div>);
export const Small1 = (props) => (<div className={`size-14-20 ${props.className || ""}`}>{props.children}</div>);
export const Small2 = (props) => (<div className="size-14-16 mb-16 bold">{props.children}</div>);
export const Small3 = (props) => (<div className={`size-12-20 ${props.className || ""}`}>{props.children}</div>);
export const LightSmall1 = (props) => (<div className={`size-14-20 content-secondary ${props.className || ""}`}>{props.children}</div>);
export const LightSmall3 = (props) => (<div className={`size-12-20 content-secondary ${props.className || ""}`}>{props.children}</div>);