import { Header4, LightHeader5, LightSmall3, TooltipButton } from "../atoms"

export const Entitle = (props) => {
    const { title, sub_title, footer, info, tooltipLabel } = props;
    return (<>
        <div className="row mb-14">
            <div className="col-sm-8">
                <Header4>{title}</Header4>
                <LightHeader5>{sub_title}</LightHeader5>
            </div>
            <div className="col-sm-4 txt-align-right">
                {tooltipLabel ? <TooltipButton title={info}>{tooltipLabel}</TooltipButton> : null}
            </div>
        </div>
        {props.children}
        <LightSmall3 className="mt-22">{footer}</LightSmall3>
    </>)
}