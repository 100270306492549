import { ImageLink } from "../atoms/Links";
import appStoreIcon from '../../static/images/download_app_store.png';
import googlePlayIcon from '../../static/images/download_google_play.png';
export const VerticalAppLinks = () => (<div className='mtb-20'>
    <div className='mb-16'>
        <ImageLink href="https://apps.apple.com/us/app/soothe-in-home-massage/id811054908?ls=1" src={appStoreIcon} alt="Apple store" />
    </div>
    <div>
        <ImageLink href="https://play.google.com/store/apps/details?id=com.soothe.client&hl=en_US" src={googlePlayIcon} alt="Google play store" />
    </div>
</div>)