import React from 'react';
// import { navigationViewLegalPages } from './../../Shared/WebAnalytics.js';
// TODO what about the analytics events
import { Max75Div, Small3, UnderlinedWhiteLink } from '../atoms';
import { HorizontalFooterLinks, VerticalFooterLinks, VerticalAppLinks, SocialLinks } from '../molecules';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }

        this.relevantLinksView = this.relevantLinksView.bind(this);
        this.appsAndSocialLinks = this.appsAndSocialLinks.bind(this);
        this.socialLinksAndCopy = this.socialLinksAndCopy.bind(this);
        this.getAppsSection = this.getAppsSection.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }
    relevantLinksView() {
        if (this.state.width >= 992) {
            return (<div className='pb-32 border-bottom-transparent'>
                <HorizontalFooterLinks />
            </div>)
        }
        return (<VerticalFooterLinks />)
    }
    appsAndSocialLinks() {
        return (<div className={`row sm-p-16 pt-32 ${this.state.width >= 992 ? "" : "text-align-center"}`}>
            <div className='col-12 col-md-2'>
                {this.getAppsSection()}
            </div>
            <div className='col-12 col-md-10'>
                {this.socialLinksAndCopy()}
            </div>
        </div>)
    }
    socialLinksAndCopy() {
        return (<><SocialLinks />
            <Small3>
                Copyright 2021, Soothe Inc. Please read our <UnderlinedWhiteLink href="https://www.soothe.com/terms-and-conditions/"
                // onClick={() => navigationViewLegalPages()}
                >
                    Terms and Conditions</UnderlinedWhiteLink> and {this.state.width >= 992 ? <br /> : null}<UnderlinedWhiteLink href="https://www.soothe.com/privacy-policy/"
                    // onClick={() => navigationViewLegalPages()}
                    > Privacy Policy</UnderlinedWhiteLink> for more information.
            </Small3>
        </>)
    }
    getAppsSection() {
        return (<VerticalAppLinks />)
    }
    render() {
        return (<footer className='background-darkest-blue content-primary-white ptb-40 sm-ptb-0-40'>
            <Max75Div className="sm-p-0">
                {this.relevantLinksView()}
                {this.appsAndSocialLinks()}
            </Max75Div>
        </footer>)
    }
}