import React from 'react';
import { connect } from 'react-redux';
import { LocationOn, AccessTime } from '@mui/icons-material';
import { CardWithBorderP24, FlexDivSpaceBetween, Header1, Header2, Header5, Header6, Small1 } from '../atoms';
import { HorizontalIconWithText } from '../molecules/ImageWithDescription';
import { get, indexOf, isEmpty, map } from 'lodash';
import moment from 'moment';
const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class B2bOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }
        this.description = this.description.bind(this);
        this.info = this.info.bind(this);
        this.responsiveCard = this.responsiveCard.bind(this);
        this.responsiveInfo = this.responsiveInfo.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }
    description() {
        return (<>
            {this.state.width >= 768 ?
                <FlexDivSpaceBetween>
                    <Header1 className="mb-32">{get(this.props, "b2bInfo.name", "")}</Header1>
                    <div className='d-sm-none d-md-block txt-align-right'><img src={get(this.props, "b2bInfo.logo", "")} alt="Client_logo" className='size-52x52 object-fit-contain rounded_logo_with_bg' /></div>
                </FlexDivSpaceBetween>
                : null}
            <div className='txt-align-left overflow-wrap-break-word'>
                <Header5 className="mb-16">{get(this.props, "b2bInfo.description", "")}</Header5>
            </div>
        </>)
    }
    info() {
        const { address, working_hours } = get(this.props, "b2bInfo", {}),
            today = moment().format('L');
        return (<Header6>
            {address ? <HorizontalIconWithText
                icon={<LocationOn style={{ fontSize: "20px" }} />}
                text={<>
                    {get(address, "address_line_1", "")}, {get(address, "city", "")}, {get(address, "state", "")} {get(address, "zip_code", "")}
                </>}
            /> : null}

            {!isEmpty(working_hours) ? <HorizontalIconWithText
                icon={<AccessTime style={{ fontSize: "20px" }} />}
                text={<div className='text-align-justify'>
                    {map(working_hours, (hrs, it) => {
                        const { weekname, from, till } = hrs;
                        return <div key={`workingday-${it}`} className="display-flex justify-content-space-between">
                            <div className="min-w-37">{moment.weekdaysShort(indexOf(WEEK_DAYS, weekname))}</div>
                            <div>:</div>
                            <div className="min-w-73 txt-align-right">{moment(`${today} ${from}`).format('h:mm a')}</div>
                            <div>-</div>
                            <div className="min-w-73 txt-align-right">{moment(`${today} ${till}`).format('h:mm a')}</div>
                        </div>
                    })}
                </div>}
            /> : null}
        </Header6>)
    }
    responsiveInfo() {
        const { address } = get(this.props, "b2bInfo", {});
        return (<Header6>
            {address ? <div className='mb-24'>
                {get(address, "address_line_1", "")}, {get(address, "city", "")}, {get(address, "state", "")} {get(address, "zip_code", "")}
            </div> : null}
        </Header6>)
    }
    responsiveCard() {
        if (this.state.width >= 768) {
            return (<CardWithBorderP24>
                <div className={`row`}>
                    <div className='col-sm-7 space-column-right'>
                        {this.description()}
                    </div>
                    <div className='col-sm-5'>
                        {this.info()}
                    </div>
                </div>
            </CardWithBorderP24>)
        }
        return (<>
            <Header2 className="m-b-4">{get(this.props, "b2bInfo.name", "")}</Header2>
            <div className='content-secondary'>
                {this.responsiveInfo()}
                <Small1 className="txt-align-left">
                    {this.description()}
                </Small1>
            </div>
        </>)
    }
    render() {
        return (this.responsiveCard())
    }
}
const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo
});

export default connect(mapStateToProps)(B2bOverview);