import React from 'react';
import { CloseButton, FlexDivCentered } from '../atoms';
import CTAStickyFooter from '../molecules/CTAStickyFooter';

export default class ModalWithImageHeader extends React.Component {
    render() {
        if (this.props.isOpen) {
            let { headerStylingClass, headerImg, applyBtnCopy, footerAddon, disabled, hideFooter } = this.props;
            return (<FlexDivCentered key="centered-modal" className="background-black-03 top-fixed width-100 height-100 z-index-2 pt-32 sm-p-0 overflow-y-scroll hide-scroll">
                <div className="p-relative width-35 sm-width-100 background-primary modal-shadow 
                border-radius-8 sm-border-radius-0 max-modal-height sm-max-modal-height mt-40 sm-mt-0 overflow-y-scroll">
                    <CloseButton close={this.props.close} />
                    {headerImg ? <div className={`height-35 img-bg ${headerStylingClass || ""} `} style={{ backgroundImage: `url(${headerImg})` }}></div> : <div className='height-49'></div>}
                    <div className='p-16-24-32'>
                        <div >{this.props.children}</div>
                    </div>
                   {hideFooter? null: <div className="txt-align-right bottom-sticky background-primary">
                        {/* TODO use common cta */}
                        <CTAStickyFooter text={applyBtnCopy || "Continue"} addon={footerAddon || null}
                            disabled={disabled}
                            action={(e) => {
                                this.props.apply(e);
                            }}
                        />
                    </div>}
                </div>
            </FlexDivCentered>
            );
        } else {
            return null;
        }
    }
}