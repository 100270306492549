import React from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, map, size, find, forEach, isUndefined, toLower, omit, pick, round, replace, orderBy } from 'lodash';
import ModalWithImageHeader from './PopupModal';
import { addToCartEvent, treatmentSelectionEvent } from '../../helpers';
import { setB2bInfo, setCart } from '../../actions';
import { DefaultInput, Header3, Header4, Header6, SecondaryCtaButton } from '../atoms';
import { BubbleOptions, Entitle } from '../molecules';

class Treatments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: null,
            isModalOpen: false,
            product: null,
            clientName: "",
            options: {},
            width: window.innerWidth
        }
        this.treatmentsMenuGroupedByService = this.treatmentsMenuGroupedByService.bind(this);
        this.styledOptions = this.styledOptions.bind(this);
        this.selectedServiceModal = this.selectedServiceModal.bind(this);
        this.detailedRadioType = this.detailedRadioType.bind(this);
        this.genderSection = this.genderSection.bind(this);
        this.sessionsSection = this.sessionsSection.bind(this);
        this.clientNameInput = this.clientNameInput.bind(this);
        this.initializeOptions = this.initializeOptions.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.formatTitle = this.formatTitle.bind(this);
        this.formatSubtitle = this.formatSubtitle.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.isMassageType = this.isMassageType.bind(this);
        this.getDuration = this.getDuration.bind(this);
        this.getModalityTitle = this.getModalityTitle.bind(this);
        this.getGender = this.getGender.bind(this);
        this.getDiscount = this.getDiscount.bind(this);
    }
    componentDidMount() {

        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }
    isMassageType() {
        return get(this.props, "cart.slug", "") === "Massage";
    }
    getModalityTitle() {
        return get(this.state, "product.opt.title");
    }
    getDuration() {
        if (this.isMassageType()) {
            const sessions = get(this.state, "product.sessions");
            const lngOpt = find(sessions, (op) => (get(this.state, "options.sessions", "") === op.service_id))
            return get(lngOpt, "title", "");
        }
        return "";
    }
    getGender() {
        if (this.isMassageType()) {
            const gender = get(this.state, "product.gender");
            const lngOpt = find(gender, (op) => (get(this.state, "options.gender", "") === op.service_id))
            return get(lngOpt, "title", "");
        }
        return "";
    }
    formatTitle() {
        const title = get(this.state, "product.opt.title", "");
        if (this.isMassageType()) {
            return `${this.getDuration()} ${title} for ${this.state.clientName}`;
        }
        return title;
    }
    formatSubtitle() {
        if (this.isMassageType()) {
            return `with ${this.getGender()} provider`;
        }
        return "";
    }
    getDiscount(prodId, rawPrice) {
        let discounts = get(this.props, "b2bInfo.discounts", {}),
            prodDis = find(discounts, (el) => (el.product_id === prodId)),
            perc = get(prodDis, "discount_percentage", 0);
        if (isEmpty(discounts) || isEmpty(prodDis)) {
            return 0;
        }
        if (perc) {
            return round((Number(rawPrice) * perc) / 100, 2);
        }
        return get(prodDis, "discount_amount", 0);
    }
    getPrice() {
        const opt = get(this.state, "product.opt", null),
            prodId = get(this.state, "product.productId", "");
        if (this.isMassageType()) {
            const sessions = get(this.state, "product.sessions", []);
            const lngOpt = find(sessions, (op) => (get(this.state, "options.sessions", "") === op.service_id))
            const total = get(lngOpt, "rawPrice", "") ? Number(get(lngOpt, "rawPrice", "")) - this.getDiscount(prodId, get(lngOpt, "rawPrice", "")) + Number(get(lngOpt, "markup", "")) + Number(get(this.props, "b2bInfo.parking_fee", "")) : 0;
            return `${get(lngOpt, "currency", "")}${round(total,2).toFixed(2).replace(/[.,]00$/, "") || ""}`;

        }
        const subT = get(opt, "rawPrice", "") ? Number(get(opt, "rawPrice", "")) - this.getDiscount(prodId, get(opt, "rawPrice", "")) + Number(get(opt, "markup", "")) + Number(get(this.props, "b2bInfo.parking_fee", "")) : 0;
        return `${get(opt, "currency", "")}${round(subT,2).toFixed(2).replace(/[.,]00$/, "") || ""}`;
    }
    addToCart() {
        let cartProducts = get(this.props, "cart.cartProducts", []);

        if (size(cartProducts) < 2) {
            const product_id = get(this.state, "product.productId", "");
            cartProducts.push({
                product_id,
                options: this.state.options,
                title: this.formatTitle(),
                subtitle: this.formatSubtitle(),
                price: this.getPrice(),
                clientName: this.state.clientName
            })
            addToCartEvent(toLower(get(this.state, "product.category", "")), {
                duration: this.getDuration(),
                modality: toLower(this.getModalityTitle()),
                gender: toLower(this.getGender()),
                client_name: this.state.clientName
            })
            this.props.setCart({ ...this.props.cart, cartProducts })
            this.setState({ clientName: "" })
            if (this.state.width < 768 && this.props.openCartDrawer) {
                this.props.openCartDrawer()
            }
        }
    }
    initializeOptions(modalityId) {
        const opts = pick(get(this.state, "product", {}), ["gender", "sessions"]);
        let options = { modality: modalityId };
        forEach(opts, (optn, ky) => {
            options[ky] = null;
            forEach(optn, (val) => {
                if (val && (val.default || size(optn) === 1)) {
                    options[ky] = val.service_id;
                }
            })
        })
        this.setState({ options })
    }
    styledOptions(prod, index, disabled = false) {
        let prodId = get(prod, "productId", "");
        let cleanArr = get(find(prod.data, (el) => (el.subCategory !== "Enhancements")), "options", []),
            ln = size(cleanArr);
        const cartFilled = size(get(this.props, "cart.cartProducts", [])) >= 2;
        return (<div className='row mb-40'>

            {map(cleanArr, (opt, itr) => {
                let parking_fee = Number(get(this.props, "b2bInfo.parking_fee", "")),
                    prc = Number(get(opt, "rawPrice", "")) - this.getDiscount(prodId, get(opt, "rawPrice", "")) + Number(get(opt, "markup", "")) + parking_fee,
                    relevantBtnId = replace(`${get(opt, "title", "")}${get(prod, "category", "")}`, /[^a-zA-Z0-9]/g, '');

                let imgDiv = null,
                    isLastElement = itr === ln - 1,
                    isOfLast2Elements = itr === ln - 1 || itr === ln - 2;
                if (this.state.width >= 768 && ((index % 2 !== 0 && itr === 0) || (index % 2 === 0 && (itr === 1 || ln === 1)))) {
                    imgDiv = (<div className='col-12 col-sm-6 sm-display-none prl-16' key={`img-${index}-${itr}`}>
                        <img className='width-100 height-130 object-fit-cover' src={get(prod, "categoryImage", "")} alt={opt.title || ""} />
                    </div>)
                }
                return (<div key={`wrapper-treatment-option-${itr}`} className="display-contents mb-40">
                    {imgDiv}
                    <div key={`treatment-option-${itr}`}
                        className='col-12 col-lg-6 prl-16'>
                        <div className={`ptb-16  display-flex align-items-center ${(isOfLast2Elements && this.state.width >= 768) || isLastElement ? "" : "double-light-border-bottom"}`}>
                            <div className="mr-16 flex-grow-1">
                                <div className='content-primary size-16-24 medium-font m-b-4 bold'>{opt.title || ""}</div>
                                <div className='content-secondary size-14-20 mb-12'>
                                    {get(opt, "description", "")}
                                </div>
                                <div className='content-secondary size-14-16 add_weight'>
                                    {isEmpty(prod.sessions) ?
                                        <span>{opt.currency}{round(prc,2).toFixed(2).replace(/[.,]00$/, "")}</span>
                                        :
                                        map(prod.sessions, (sess, ii) => (<span key={`prc-${ii}-${itr}-${index}`}>{sess.title} {sess.currency}{round(sess.rawPrice - this.getDiscount(prodId, sess.rawPrice) + Number(sess.markup) + parking_fee, 2).toFixed(2).replace(/[.,]00$/, "")}{ii !== size(prod.sessions) - 1 ? <span className='color-light-gray bold'> | </span> : null}</span>))
                                    }
                                </div>
                            </div>
                            {cartFilled ? null : <div>
                                <SecondaryCtaButton text="Select" disabled={disabled} relevantId={relevantBtnId}
                                    onClick={() => {
                                        treatmentSelectionEvent(toLower(get(prod, "category", "")), toLower(get(opt, "title", "")))
                                        this.setState({ product: { ...omit(prod, "data"), opt }, isModalOpen: true }, () => {
                                            this.props.setCart({ ...this.props.cart, product_id: prod.productId, slug: prod.category })
                                            this.initializeOptions(opt.service_id);
                                        })
                                    }} />
                            </div>}
                        </div>
                    </div></div>)
            })}
        </div>)

    }
    treatmentsMenuGroupedByService() {
        let products = get(this.props, "b2bInfo.services", []);

        return map(products, (prod, index) => {
            const selectedProdId = get(this.props, "cart.product_id", "");
            let disabled = selectedProdId && prod.productId !== selectedProdId;

            let hasValidOptions = prod.data.some(dataItem => !isEmpty(dataItem.options));
            let isMassageCategory = get(prod, "category", "").toLowerCase() === "massage";
            let hasMassageDetails = isMassageCategory && (!isEmpty(prod.sessions) || !isEmpty(prod.gender));
            if (hasValidOptions) {
                return (
                    <div key={`treatment-${index}`} className={`mb-40 ${disabled ? "opacity-05" : ""}`}>
                        <Header4 className={`pb-8 content-primary ${this.state.width >= 768 ? "border-bottom-accent" : ""}`}>{get(prod, "category", "")}</Header4>
                        {this.styledOptions(prod, index, disabled)}
                    </div>
                );
            }

            return null;
        });
    }

    detailedRadioType() {
        const option = get(this.state, "product.opt", null);
        if (!isEmpty(option)) {
            return (<div className='mb-32'>
                <Header3>{get(option, "title", "")}</Header3>
                {this.state.width >= 768 ? <Header6>{get(option, "longDescription", "")}</Header6> : null}
            </div>)
        }
    }
    genderSection() {
        const gender = get(this.state, "product.gender", [])
        if (!isEmpty(gender)) {
            return <div className="mb-32">
                <Entitle title={"Provider Gender"} sub_title={"Choose one • Required"} footer={"Appointments with Any gender get accepted sooner"}>
                    <BubbleOptions name={"gender"} list={orderBy(gender, "order")} selection={get(this.state, `options.gender`, "")}
                        onClick={(value) => {
                            let { options } = this.state;
                            options["gender"] = value;
                            this.setState({ options })
                        }} />
                </Entitle>
            </div>
        }
    }
    sessionsSection() {
        const sessions = get(this.state, "product.sessions", [])
        if (!isEmpty(sessions)) {
            return <div className="mb-32">
                <Entitle title={"Session Length"} sub_title={"Choose one • Required"}>
                    <BubbleOptions name={"sessions"} list={sessions} selection={get(this.state, `options.sessions`, "")}
                        onClick={(value) => {
                            let { options } = this.state;
                            options["sessions"] = value;
                            this.setState({ options })
                        }} />
                </Entitle>
            </div>
        }
    }
    clientNameInput() {
        let { clientName } = this.state;
        return (<Entitle title="Client name" sub_title="Enter name • Required">
            <DefaultInput isValid={clientName} value={clientName} onChange={(clientName) => this.setState({ clientName })} />
        </Entitle>)
    }
    selectedServiceModal() {
        let { clientName, options } = this.state;
        const option = get(this.state, "product.opt", null)
        return (<ModalWithImageHeader isOpen={this.state.isModalOpen}
            disabled={!clientName}
            headerImg={get(option, "image", "")}
            applyBtnCopy="Add to cart"
            footerAddon={{ content: this.getPrice() }}
            close={() => {
                const cartProducts = get(this.props, "cart.cartProducts", []);
                if (isEmpty(cartProducts)) {
                    this.props.setCart({ ...this.props.cart, product_id: "", slug: "" })
                }
                this.setState({ isModalOpen: false })
            }}
            apply={() => {
                if (clientName && !find(options, (val) => (isUndefined(val)))) {
                    this.addToCart();
                    this.setState({ isModalOpen: false })
                }
            }}>
            {this.detailedRadioType()}
            {this.sessionsSection()}
            {this.genderSection()}
            {this.clientNameInput()}
        </ModalWithImageHeader >)
    }
    render() {
        let isEmptyProducts = isEmpty(get(this.props, "b2bInfo.services", []));
        if (isEmptyProducts) {
            return null;
        }
        return (<div className='pt-88 mb-48' id="treatments">
            <div className='content-primary size-36-44 medium-font mb-24'>Treatments Menu</div>
            {this.treatmentsMenuGroupedByService()}
            {this.selectedServiceModal()}
        </div>)
    }
}
const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo,
    cart: state.cart
});

export default connect(mapStateToProps, { setB2bInfo, setCart })(Treatments);
