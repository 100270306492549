import React from 'react';
import { connect } from 'react-redux';
import whiteLogo from "../../static/images/Soothe-logo-white-svg.svg";
import blueLogo from "../../static/images/Soothe-logo-blue-svg.svg";
import smallWhiteLogo from '../../static/images/White-small-logo-svg.svg';
import smallBlueLogo from '../../static/images/Blue-small-logo-svg.svg';
import { Header5, WhiteLink } from '../atoms';
import { get } from 'lodash';
import { ArrowBack } from '@mui/icons-material';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledDown: false,
            width: window.innerWidth
        }
        this.relevantHeader = this.relevantHeader.bind(this);
    }
    componentDidMount() {
        let self = this;
        window.addEventListener('scroll', function (e) {
            if (!self.state.scrolledDown && window.scrollY > 0) {
                self.setState({ scrolledDown: true })
            }
            else if (window.scrollY === 0 && self.state.scrolledDown) {
                self.setState({ scrolledDown: false })
            }
        });

        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
    }
    relevantHeader() {
        const { scrolledDown, width } = this.state;
        let website_link = get(this.props, "b2bInfo.website_link", "");

        if (website_link && !/^https?:\/\//i.test(website_link)) {
            website_link = 'http://' + website_link;
        }
        if (scrolledDown || !get(this.props, "b2bInfo.bannerimage", "")) {
            return (<header className='top-fixed z-index-1 text-align-center background-primary light-border-bottom p-16-64 sm-p-16'>
                <div className='row align-items-center'>
                    <div className='col-4 txt-align-left'>
                        {website_link ?
                            <WhiteLink className="content-accent text-decoration-none" href={website_link}>
                                <Header5 className=" sm-mr-0">
                                    <ArrowBack className='mr-16' />
                                    <span className=' sm-none'>{get(this.props, "b2bInfo.name", "")}</span>
                                </Header5>
                            </WhiteLink>
                            : null}
                    </div>
                    <div className='col-4 text-align-center'>
                        {width <= 992 ? <img src={smallBlueLogo} alt="Soothe logo" />
                            : <img src={blueLogo} alt="Soothe logo" />}
                    </div>
                </div>
            </header>)
        }
        return (<header className='top-fixed light-border-bottom p-16-64 sm-p-16'>
            <div className='row align-items-center'>
                <div className='col-4 txt-align-left'>
                    {website_link ?
                        <WhiteLink className="content-primary-white" href={website_link}>
                            <Header5 className=" sm-mr-0">
                                <ArrowBack className='mr-16' />
                                <span className=' sm-none'>{get(this.props, "b2bInfo.name", "")}</span>
                            </Header5>
                        </WhiteLink>
                        : null}
                </div>
                <div className='col-4 text-align-center'>
                    {width <= 992 ? <img src={smallWhiteLogo} alt="Soothe logo" className='height-49' />
                        : <img src={whiteLogo} alt="Soothe logo" className='height-49' />}
                </div>
            </div>
        </header>)
    }
    render() {
        return (this.relevantHeader());
    }
}
const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo
});

export default connect(mapStateToProps, {})(Header);