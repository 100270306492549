export const HalfHeightHeroImage = (props) => (
    <div className={props.className || ""} style={{ backgroundImage: `url(${props.bgImg})`, height: "50vh", backgroundPosition: "center", backgroundSize: "cover" }}>
    </div>
);
export const SmallHalfHeightHeroImage = (props) => (
    <div className={props.className || ""} style={{
        backgroundImage: `url(${props.bgImg})`, height: "50vh", backgroundPosition: "center",
        backgroundSize: "auto 100%", backgroundRepeat: "no-repeat"
    }}>
    </div>
);