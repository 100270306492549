import Close from '@mui/icons-material/Close';
import { omit } from 'lodash';
export const CtaButton = ({ onClick, copy, disabled = false, className }) => (
    <button className={`btn width-100 size-18-24 bold background-cactus-flower content-primary-white p-16-20 border-radius-30 ${className||""}`}

        onClick={onClick} disabled={disabled}>
        {copy}
    </button>
)
export const CloseButton = (props) => (
    <div className='absolute-tl-12'>

        <button className={`btn content-secondary border-radius-48 limit-to-48 display-flex p-13 background-light-gray justify-content-centered align-items-center ${props.className||""}`}
            onClick={(e) => {
                e.preventDefault();
                props.close();
            }}>
            <Close />
        </button>
    </div>
)
export const SecondaryCtaButton = (props) => (
    <button className={`btn size-14-16 background-accent-light content-accent border-radius-9999 p-10-12 ${props.className || ""}`}
        {...omit(props, "className")} {...(props.relevantId ? { id: props.relevantId } : {})}>{props.text}</button>
)
export const LinkButton = ({ text, href, className }) => (
    <a className={`btn width-100 size-18-24 background-accent-light content-accent p-16-20 border-radius-30 ${className || ""}`}
        href={href}>{text}</a>
)

export const LinkCtaButton = ({ copy, href }) => (
    <a className='btn width-100 size-18-24 bold background-cactus-flower content-primary-white p-16-20 border-radius-30'
        href={href}>{copy}</a>
)
export const TooltipButton = (props) => (
    <button type="button" className='btn infoButton'
        data-toggle="tooltip" data-placement="bottom"
        title={props.title}>{props.children}</button>
)
export const RoundedIcon = (props) => (
    <button className='btn content-dark-gray border-radius-36 limit-to-36 background-gray'
        onClick={(e) => {
            e.preventDefault();
            props.onClick();
        }}>
        {props.icon}
    </button>
)