import axios from 'axios';
import {NODE_API_ROOT, ACCEPT_HEADER, API_ROOT} from '../apiConfig';
export function countVisit(locationUUID, sub_domain, callBackFunc, errorHandler) {
    if (locationUUID) {
        axios.post(`${API_ROOT}/partner/locations/increment_view_count`,
            {
                locationUUID,
                sub_domain
            },

            { withCredentials: false }, ACCEPT_HEADER).then((response) => {
                let { success } = response.data;
                if (success && callBackFunc) {
                    callBackFunc(response);
                }
            }).catch((err) => {
                if (errorHandler) {
                    errorHandler(err);
                } else {
                    console.log(err);
                }
            });
    }
}
