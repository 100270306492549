import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import thunk from 'redux-thunk';
import * as screenActions from './actions';
import reducer from './reducers';
import RootContainer from './App';


const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(reducer);



// name has to be capitalised for React to recognise it as an element in JSX
const ConnectedRoot = connect(
  (state) => ({
    state: state.reducer
  }),
  (dispatch) => ({
    actions: bindActionCreators(screenActions, dispatch)
  })
)(RootContainer);



/**************** */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ConnectedRoot />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();