import { combineReducers } from 'redux';
import B2bBookingFlowReducer from './B2bBookingFlowReducer';
import CartReducer from './CartReducer';
import LoadingReducer from './LoadingReducer';

const rootReducer = combineReducers({
  b2bInfo: B2bBookingFlowReducer,
  cart: CartReducer,
  loading: LoadingReducer
});

export default rootReducer;
