import React from 'react';
import { connect } from 'react-redux';
import maintenanceImage from '../../../static/images/maintenanceImage.svg';

class Maintenance extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className="height-100 width-45 sm-width-100 text-align-center p-45 sm-p-16">
            <div className='mb-64'><img src={maintenanceImage} alt="alert" /></div>
            <div className="size-32-40 mb-8 color-ash bold">We'll be back soon!</div>
            <div className="size-18-24 color-dark-lavender">
                <p>
                    Soothe's Partner Platform is under maintenance getting a quick tune up. We should be back online shortly, but you can always contact us at <a href="mailto:support@soothe.com" className='color-dark-lavender text-decoration-none'>support@soothe.com</a>.
                </p>
            </div>
            <div className='size-18-24 color-dark-lavender'>Thank you for your patience!</div>
        </div>
    }
}
const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo,
    cart: state.cart
});

export default connect(mapStateToProps, {})(Maintenance);