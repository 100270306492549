import React from 'react';
import { connect } from 'react-redux';
import { Header3, Header6 } from '../atoms';
import { HorizontalImgWithText } from '../molecules';
import { get, map, slice, isEmpty } from 'lodash';
import { getBlogPosts } from '../../helpers';

class AboutAndLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            blog_posts: []
        }
        this.getAbout = this.getAbout.bind(this);
        this.getHelpfulLinks = this.getHelpfulLinks.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ width: window.innerWidth })
        });
        getBlogPosts((resp) => {
            this.setState({ blog_posts: get(resp, "data.blog_posts", []) })
        })
    }
    getAbout() {
        return (<>
            <Header3>About Soothe</Header3>
            <Header6>
                Founded in 2013, Soothe has quickly become the largest and most popular mobile wellness platform in the world. Soothe helps people by connecting clients with top wellness and personal care providers who meet their personalized needs.<br />
                Currently available in over 70 major cities across the US, UK, Ireland, Canada, and Australia, Soothe partners with more than 10,000+ independent, licensed and certified wellness providers and salon professionals.
            </Header6>
        </>)
    }
    getHelpfulLinks() {
        let additionalResponsiveClass = get(this.state, "width", 0) < 768 ? "flex-row-reverse" : "",
            posts = slice(get(this.state, "blog_posts", []), 0, 3);
        return (<>
            <Header3>Helpful Links</Header3>
            {map(posts, (post, it) => (
                <div key={`post-${it}`}>
                    <HorizontalImgWithText additionalClass={additionalResponsiveClass} href={get(post, "url", "")}
                        src={get(post, "hero", "")} alt={`hero-${it}`} label={get(post, "category", "")}
                        text={get(post, "title", "")} />
                </div>))}
        </>)
    }

    render() {
        let isEmptyPosts = isEmpty(get(this.state, "blog_posts", []));
        return (<div className='background-primary p-24-0 sm-p-0 text-align-justify'>
            <div className='row'>
                <div className='col-12 col-md-6 p-24'>
                    {this.getAbout()}
                </div>
                {isEmptyPosts ? null : <div className='col-12 col-md-6 p-24'>
                    {this.getHelpfulLinks()}
                </div>}
            </div>
        </div>)
    }
}

const mapStateToProps = state => ({
    b2bInfo: state.b2bInfo
});

export default connect(mapStateToProps, {})(AboutAndLinks);